import React, { useCallback, useMemo, useState } from 'react';
import {
  AddressLink,
  ContactJob,
  ContactName,
  ContactPhoto,
  ContactPhotoPlaceholder,
  Container,
  MetaData,
  ProjectManager,
  Title,
  Chips,
  CollapseButton,
  ProjectImageContainer,
  ManagerAndUpload,
  ArchivedMark,
  PhasesTrack,
  Phase,
} from './styled';
import { ProjectImage } from '../ProjectImage';
import { getGeoLink } from '../../../utils/getGeoLink';
import { MapPinIcon } from '../../ui/icons/MapPin';
import { useProjects } from '../../../apiHooks/useProjects';
import { UserIcon } from '../../ui/icons/User';
import { Stages } from './Stages';
import { filterNotNull } from '../../../utils/types/filterNotNull';
import { Button, ButtonVariant } from '../../ui/Button';
import { ChevronDownIcon } from '../../ui/icons/ChevronDown';
import { ChevronUpIcon } from '../../ui/icons/ChevronUp';
import { Badge } from '../../ui/Badge';
import { UploadIcon } from '../../ui/icons/Upload';
import { formatMoney } from '../../../utils/formatMoney';
import { useModal } from '../PromiseModal';
import { Upload } from '../../../features/Docs';
import { getProjectStageStatus } from './helpers';

interface Props {
  isCollapsible?: boolean;
  project: Exclude<ReturnType<typeof useProjects>['data'], undefined>[number];
}

export const Project = ({ project, isCollapsible = false }: Props) => {
  const [isExpanded, setIsExpanded] = useState(!isCollapsible);

  const { openModal } = useModal();

  const stages = useMemo(
    () =>
      filterNotNull(
        [...(project.blueprint?.blueprintProjectStages ?? [])]
          .sort((a, b) => a.position - b.position)
          .map((workflowStage) =>
            workflowStage.projectStage
              ? {
                  ...workflowStage.projectStage,
                  actions: project.actionsByProjectId.filter(
                    (action) =>
                      action.projectStageId === workflowStage.projectStage?.id,
                  ),
                  tasks: filterNotNull(
                    project.tasksByProjectIdConnection.nodes,
                  ).filter(
                    (task) =>
                      task.projectStageId === workflowStage.projectStage?.id,
                  ),
                }
              : null,
          ) ?? [],
      )
        .filter((stage) => stage?.type !== 'CANCELLED')
        .map((stage, _index, allStages) => ({
          ...stage,
          status: getProjectStageStatus({
            stage,
            allStages,
            currentStageId: project.stageId,
          }),
        })),
    [
      project.blueprint?.blueprintProjectStages,
      project.actionsByProjectId,
      project.tasksByProjectIdConnection.nodes,
      project.stageId,
    ],
  );

  const handleUploadClick = useCallback(() => {
    openModal<void>(
      ({ onClose }) => <Upload projectId={project.id} onClose={onClose} />,
      {
        title: 'Upload files',
      },
    );
  }, [openModal, project.id]);

  const toggleExpand = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  return (
    <Container isArchived={!project.isActive} isExpanded={isExpanded}>
      <div>
        <MetaData>
          <ProjectImageContainer>
            {!project.isActive && <ArchivedMark>Archived</ArchivedMark>}
            <ProjectImage
              imageUrl={project.imageUrl || project.streetViewUrl}
            />

            <Chips>
              {project.trades?.map((trade) => (
                <Badge key={trade} color="#1D1D35" bgColor="#FFF">
                  {trade}
                </Badge>
              ))}

              {Boolean(project.projectSize) && (
                <Badge color="#1D1D35" bgColor="#FFF">
                  {project.projectSize} kW
                </Badge>
              )}

              {Boolean(project.projectValue) &&
                project.projectValue !== null && (
                  <Badge color="#1D1D35" bgColor="#FFF">
                    {formatMoney(project.projectValue)}
                  </Badge>
                )}
            </Chips>
          </ProjectImageContainer>
          <div>
            <Title>{project.title}</Title>

            {Boolean(project.address?.[0]) && (
              <AddressLink href={getGeoLink(project.address?.[0] ?? '')}>
                <MapPinIcon size="16px" />
                {project.address?.[0]}
              </AddressLink>
            )}

            <ManagerAndUpload>
              {project.projectManager && (
                <ProjectManager>
                  {project.projectManager.avatarUrl && (
                    <ContactPhoto
                      size={32}
                      src={project.projectManager.avatarUrl}
                      alt={`${project.projectManager.firstName} ${project.projectManager.lastName} `}
                    />
                  )}
                  {!project.projectManager.avatarUrl && (
                    <ContactPhotoPlaceholder size={32}>
                      <UserIcon size="24px" />
                    </ContactPhotoPlaceholder>
                  )}
                  <div>
                    <ContactName>
                      {project.projectManager.firstName}{' '}
                      {project.projectManager.lastName}
                    </ContactName>
                    <ContactJob>Your Project Manager</ContactJob>
                  </div>
                </ProjectManager>
              )}

              <div>
                <Button
                  variant={ButtonVariant.Flat}
                  onClick={handleUploadClick}
                >
                  <UploadIcon size="16px" />
                  Upload Docs
                </Button>
              </div>
            </ManagerAndUpload>

            {stages.length > 0 && project.isActive && (
              <PhasesTrack>
                {stages.map((stage) => (
                  <Phase key={stage.id} status={stage.status} />
                ))}
              </PhasesTrack>
            )}
          </div>
        </MetaData>
      </div>
      <div>
        {isExpanded && (
          <Stages currentStageId={project.stageId} stages={stages} />
        )}
      </div>

      {isCollapsible && (
        <CollapseButton isCollapsed={!isExpanded} onClick={toggleExpand}>
          {isExpanded ? 'Collapse details' : 'Open details'}
          {isExpanded ? (
            <ChevronUpIcon size="16px" />
          ) : (
            <ChevronDownIcon size="16px" />
          )}
        </CollapseButton>
      )}
    </Container>
  );
};
