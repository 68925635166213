import React, { useCallback, useEffect, useState } from 'react';
import { authApi } from '../../../api/auth';
import { Button, ButtonVariant } from '../../../kit/ui/Button';
import { Input } from '../../../kit/ui/Input';
import {
  Asterisk,
  CompanyName,
  Container,
  ErrorMessage,
  FieldError,
  FieldLabel,
  Form,
  FormContent,
  HelpText,
  Hint,
  InnerContainer,
  InputSeparator,
  LinkButton,
  Logo,
  LogoImage,
  Title,
  VerificationContent,
} from './styled';
import OTPInput from 'react-otp-input';
import { ApiError } from '../../../api/ApiError';
import { sessionService } from '../../../services/SessionService';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCompany } from '../../../apiHooks/useCompany';
import { ArrowLeftIcon } from '../../../kit/ui/icons/ArrowLeft';
import { ButtonSize } from '../../../kit/ui/Button';
import { RadioGroup } from '../../../kit/ui/RadioGroup';
import { storage } from '../../../services/storage';

const OTP_LENGTH = 6;

enum LoginMethod {
  Phone = 'phone',
  Email = 'email',
}

const USER_LOGIN_KEY = 'user_login';

const LOGIN_OPTIONS = [
  { label: 'Phone', value: LoginMethod.Phone },
  { label: 'Email', value: LoginMethod.Email },
];

const formatPhone = (phone: string) => {
  let formatted = phone.trim().replace(/[^+0-9]/g, '');

  if (formatted.startsWith('+')) {
    return formatted;
  }

  if (formatted.startsWith('1') && formatted.length === 11) {
    return `+${formatted}`;
  }

  return `+1${formatted}`;
};

const formatEmailOrPhone = (emailOrPhone: string, loginMethod: LoginMethod) => {
  if (!emailOrPhone) {
    return '';
  }
  
  return loginMethod === LoginMethod.Email
    ? emailOrPhone.trim().toLowerCase()
    : formatPhone(emailOrPhone);
};

export const Login = () => {
  const [params] = useSearchParams();

  const initialValue =
    params.get('email') ||
    params.get('phone') ||
    storage.getItem(USER_LOGIN_KEY) ||
    '';

  const [selectedLoginOption, setSelectedLoginOption] = useState(
    initialValue.includes('@') ? LoginMethod.Email : LoginMethod.Phone,
  );

  const [emailOrPhone, setEmailOrPhone] = useState(
    params.get('email') ||
      params.get('phone') ||
      storage.getItem(USER_LOGIN_KEY) ||
      '',
  );
  const [otp, setOtp] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const navigate = useNavigate();
  const { workspaceSlug } = useParams();

  const [canResendAt, setCanResendAt] = useState<number | null>(null);
  const [remainingTime, setRemainingTime] = useState<number | null>(null);
  const { data: company } = useCompany(workspaceSlug ?? '');

  const [step, setStep] = useState<'request' | 'verify'>('request');
  const companyId = company?.id ?? 0;

  useEffect(() => {
    setErrorMessage(null);
  }, [otp, emailOrPhone]);

  const requestCode = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    try {
      e.preventDefault();
      if (emailOrPhone.trim() === '') {
        setErrorMessage('Login is required');
        return;
      }
      setOtp('');
      setErrorMessage(null);
      setIsSubmitting(true);

      const loginOption = emailOrPhone.includes('@')
        ? LoginMethod.Email
        : LoginMethod.Phone;

      const formattedEmailOrPhone = formatEmailOrPhone(emailOrPhone, loginOption);

      const { canRequestAgainInSec } = await authApi.requestOtp(
        formattedEmailOrPhone,
        companyId,
      );
      setCanResendAt(Date.now() + canRequestAgainInSec * 1000);
      setRemainingTime(canRequestAgainInSec);
      setIsSubmitting(false);
      storage.setItem(USER_LOGIN_KEY, emailOrPhone);
      setSelectedLoginOption(loginOption);
      setStep('verify');
    } catch (error) {
      setIsSubmitting(false);

      if (error instanceof ApiError) {
        setErrorMessage(
          typeof error.payload === 'string' ? error.payload : error.message,
        );
      } else {
        setErrorMessage('Something went wrong');
      }
    }
  };

  const login = useCallback(async () => {
    try {
      setErrorMessage(null);
      setIsSubmitting(true);

      const formattedEmailOrPhone = formatEmailOrPhone(emailOrPhone, selectedLoginOption);

      const { accessToken, expiresIn } = await authApi.login(
        formattedEmailOrPhone,
        otp,
        companyId,
      );

      sessionService.setToken(workspaceSlug!, accessToken, expiresIn);

      setIsSubmitting(false);

      navigate(`/${workspaceSlug}`);
    } catch (error) {
      setIsSubmitting(false);

      if (error instanceof ApiError) {
        setErrorMessage(
          typeof error.payload === 'string' ? error.payload : error.message,
        );
      } else {
        setErrorMessage('Something went wrong');
      }
    }
  }, [emailOrPhone, otp, navigate, workspaceSlug, companyId, selectedLoginOption]);

  useEffect(() => {
    if (otp.length === OTP_LENGTH) {
      login();
    }
  }, [otp, login]);

  const handleVerify = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    login();
  };

  const handleBack = useCallback(() => {
    setStep('request');
    setErrorMessage(null);
    setOtp('');
  }, []);

  useEffect(() => {
    if (canResendAt && canResendAt <= Date.now()) {
      setCanResendAt(null);
      setRemainingTime(null);
    }

    const interval = setInterval(() => {
      if (canResendAt && canResendAt <= Date.now()) {
        setCanResendAt(null);
        setRemainingTime(null);
      }

      if (canResendAt && canResendAt > Date.now()) {
        setRemainingTime(Math.round((canResendAt - Date.now()) / 1000));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [canResendAt]);

  const handleLoginMethodChange = (value: LoginMethod) => {
    setSelectedLoginOption(value);
    setEmailOrPhone('');
  };

  const isInvalidCodeError = errorMessage === 'Invalid OTP code';
  const isEmptyLoginError = errorMessage === 'Login is required';

  if (!companyId) {
    return null;
  }

  return (
    <Container>
      <InnerContainer>
        <Logo>
          {company && Boolean(company.logoUrl) && (
            <LogoImage src={company.logoUrl} />
          )}
          {!company?.logoUrl && <CompanyName>{company?.name}</CompanyName>}
        </Logo>
        <Form onSubmit={step === 'request' ? requestCode : handleVerify}>
          <Title>Login</Title>
          {!isInvalidCodeError && !isEmptyLoginError && errorMessage && (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          )}
          {step === 'request' && (
            <FormContent>
              <RadioGroup
                name="loginMethod"
                options={LOGIN_OPTIONS}
                value={selectedLoginOption}
                onChange={handleLoginMethodChange}
              />
              <div>
                <FieldLabel htmlFor="emailOrPhone">
                  {selectedLoginOption === LoginMethod.Email
                    ? 'Enter your email'
                    : 'Enter your phone number'}{' '}
                  <Asterisk>*</Asterisk>
                </FieldLabel>
                <Input
                  id="emailOrPhone"
                  type="text"
                  name="emailOrPhone"
                  placeholder={
                    selectedLoginOption === LoginMethod.Email
                      ? 'email@example.com'
                      : ''
                  }
                  className={isEmptyLoginError ? 'error' : ''}
                  value={emailOrPhone}
                  onChange={(e) => setEmailOrPhone(e.target.value)}
                />
              </div>

              <Button
                disabled={isSubmitting}
                isBlock
                variant={ButtonVariant.Primary}
                size={ButtonSize.Large}
                type="submit"
              >
                Log in
              </Button>
            </FormContent>
          )}
          {step === 'verify' && (
            <VerificationContent>
              <Hint>
                A code was sent to the{' '}
                {selectedLoginOption === LoginMethod.Phone ? 'phone' : 'email'}{' '}
                <b>{emailOrPhone}</b>.
              </Hint>

              <div>
                <FieldLabel>
                  Enter code <Asterisk>*</Asterisk>
                </FieldLabel>

                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  containerStyle="otpContainer"
                  numInputs={OTP_LENGTH}
                  renderSeparator={<InputSeparator />}
                  renderInput={(props) => (
                    <Input
                      {...props}
                      className={`otpInput ${isInvalidCodeError && 'error'}`}
                    />
                  )}
                />
                {isInvalidCodeError && (
                  <FieldError>The entered code is incorrect.</FieldError>
                )}
              </div>

              <HelpText>
                Didn’t receive the code?{' '}
                {remainingTime && (
                  <div>
                    You can request a new code in {remainingTime} second
                    {remainingTime === 1 ? '' : 's'}
                  </div>
                )}
                {!remainingTime && (
                  <div>
                    <LinkButton onClick={requestCode}>
                      Send me a new code
                    </LinkButton>
                  </div>
                )}
              </HelpText>

              <div>
                <LinkButton disabled={isSubmitting} onClick={handleBack}>
                  <ArrowLeftIcon size="16px" />
                  BACK
                </LinkButton>
              </div>
            </VerificationContent>
          )}
        </Form>
      </InnerContainer>
    </Container>
  );
};
